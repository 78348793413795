<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar {{ nameOfCategory }}</h1>
    <h1 class="add-global-modal-main-subtitle">
      {{ subtitle }}
    </h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="add-global-modal-name">
      <b-field
        label="Clave (Requerido)"
        expanded
        label-position="inside"
        class="special-simple-margin"
        :type="{ 'is-danger': categoryRequiredErrors.CLAVE }"
        :message="{ 'La clave no es valida': categoryRequiredErrors.CLAVE }"
      >
        <b-input
          placeholder="Ejemplo: Acero"
          expanded
          icon="numeric"
          v-model="newCategory.CLAVE"
          required
        ></b-input>
      </b-field>
      <b-field
        label="Descripción (Opcional)"
        expanded
        label-position="inside"
        class="special-simple-margin"
      >
        <b-input
          placeholder="Ejemplo: Descripción de aceros"
          expanded
          icon="clipboard"
          v-model="newCategory.DESC_FAM"
        ></b-input>
      </b-field>
    </b-field>

    <b-field
      class="special-simple-margin"
      :label="'Comentario ' + (index + 1) + ' (Opcional)'"
      expanded
      label-position="inside"
      v-for="(singleComment, index) in newCategory.COMMENT"
      v-bind:key="index + 1000"
    >
      <b-input
        placeholder="Ejemplo: Familia usada en proveedor 1"
        expanded
        icon="clipboard-text-multiple-outline"
        v-model="singleComment.DESCRIPTION"
      ></b-input>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="AddMainCategory()"
        >Crear {{ nameOfCategory }}</b-button
      >
      <b-button
        type="is-danger"
        @click="deleteInformation()"
        class="cancel-button"
        >Borrar información</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";
// @ is an alias to /src
export default {
  name: "AddSubFamiliesModal",
  components: {},
  directives: { cleave },
  props: ["fathersInformation", "nameOfCategory"],
  data() {
    return {
      typeSelected: [],
      newCategory: {
        CLAVE: "",
        DESC_FAM: "",
      },
      categoryRequiredErrors: {
        CLAVE: false,
      },
      subtitle: "",
    };
  },
  mounted() {
    if (this.nameOfCategory === "Subsubsubfamilia") {
      this.subtitle = this.fathersInformation.selectedSSCat.CLAVE_SSFAM;
    } else if (this.nameOfCategory === "Subsubfamilia") {
      this.subtitle = this.fathersInformation.selectedSCat.CLAVE_SFAM;
    } else {
      this.subtitle = this.fathersInformation.familyKey;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new main category in the database, display messages according to the status
     */
    async AddMainCategory() {
      try {
        this.categoryRequiredErrors = validateFields(
          this.newCategory,
          this.categoryRequiredErrors
        );
        if (checkValidationErrors(this.categoryRequiredErrors)) {
          /**
           * Format category according to passed category name
           */
          const formatedCategory = this.selectSubCategoryType();
          let response = await this.$store.dispatch(
            "SAVEMAINSCATEGORY",
            formatedCategory
          );
          if (response == "Success") {
            this.deleteInformation();
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se guardó exitosamente la nueva información",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la información en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la información en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la información en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc Adds the necesary information depending on the typee of subfamily that wants to be added
     */
    selectSubCategoryType() {
      /**
       * Creates object with necesary information and names and returns correct category action name
       */
      let formatedCategory = {};
      let formatedSaveAction = "";
      switch (this.nameOfCategory) {
        // subCategory
        case "Subfamilia":
          formatedCategory = {
            saveAction: "SAVE_SCATEGORY",
            type: "subCategories",
            CLAVE_FAM: this.fathersInformation.familyKey,
            CLAVE_SFAM: this.newCategory.CLAVE,
            DESC_SFAM: this.newCategory.DESC_FAM,
            COMENTS: this.newCategory.COMENTS,
          };
          break;
        // subsubCategory
        case "Subsubfamilia":
          formatedCategory = {
            saveAction: "SAVE_SSCATEGORY",
            type: "subSubCategories",
            CLAVE_FAM: this.fathersInformation.familyKey,
            CLAVE_SFAM: this.fathersInformation.selectedSCat.CLAVE_SFAM,
            CLAVE_SSFAM: this.newCategory.CLAVE,
            DESC_SSFAM: this.newCategory.DESC_FAM,
            COMENTS: this.newCategory.COMENTS,
          };
          break;
        // subsubsubCategory
        case "Subsubsubfamilia":
          formatedCategory = {
            saveAction: "SAVE_SSSCATEGORY",
            type: "subSubSubCategories",
            CLAVE_FAM: this.fathersInformation.familyKey,
            CLAVE_SFAM: this.fathersInformation.selectedSCat.CLAVE_SFAM,
            CLAVE_SSFAM: this.fathersInformation.selectedSSCat.CLAVE_SSFAM,
            CLAVE_SSSFAM: this.newCategory.CLAVE,
            DESC_SSSFAM: this.newCategory.DESC_FAM,
            COMENTS: this.newCategory.COMENTS,
          };
          break;
        // subCategory
        default:
          formatedCategory = {
            saveAction: "SAVE_SCATEGORY",
            type: "subCategories",
            CLAVE_FAM: this.fathersInformation.familyKey,
            CLAVE_SFAM: this.newCategory.CLAVE,
            DESC_SFAM: this.newCategory.DESC_FAM,
            COMENTS: this.newCategory.COMENTS,
          };
          break;
      }
      return formatedCategory;
    },
    deleteInformation() {
      this.newCategory = {
        CLAVE: "",
        DESC_FAM: "",
        COMENTS: [
          {
            COMENTARIO: "",
          },
        ],
      };
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
