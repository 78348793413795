/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Familias y Subfamilias </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddCategory()"
          >Agregar familia</b-button
        >
      </div>
      <!--
            <div class="control">
                <b-button type="is-primary" @click="startImport()">Importar Proveedores</b-button>
            </div>
            -->
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de Familias: <b>{{ categories.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="categories"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Categories"
        :checked-rows.sync="checkedCategories"
        paginated
        :current-page="currentPage"
        per-page="15"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_FAM}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CLAVE_FAM"
      >
        <template #empty>
          <div class="has-text-centered">No hay categorías</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>

            <span>{{ props.row[column.field] }} </span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <div class="global-hint-no-margin title-expandable-item">
                <p>
                  <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
                  Recuerda seleccionar una
                  <span class="global-form-hint-color"> subfamilia </span>
                  primero para poder seleccionar y agregar una
                  <span class="global-form-hint-color"> subsubfamilia </span> o
                  <span class="global-form-hint-color"> subsubsubfamilia </span>
                </p>
              </div>
              <h3 class="title-expandable-item">
                <strong>Información general</strong>
              </h3>

              <div>
                <b-field grouped class="special-margin">
                  <b-field
                    label="Nombre de la familia (Requerido)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: Acero"
                      expanded
                      icon="numeric"
                      v-model="props.row.CLAVE_FAM"
                      required
                      disabled
                    ></b-input>
                  </b-field>
                  <b-field
                    label="Descripción de la familia (Opcional)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: Descripción de aceros"
                      expanded
                      icon="clipboard"
                      v-model="props.row.DESC_FAM"
                    ></b-input>
                  </b-field>
                </b-field>
                <div
                  v-for="(item, index) in props.row.COMMENT"
                  :key="index"
                  class="title-expandable-item-special"
                >
                  <b-field grouped class="special-margin">
                    <b-field
                      :label="'Comentario ' + (index + 1) + ' (Opcional)'"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: Familia usada en proveedor 1"
                        icon="office-building-outlinee"
                        v-model="item.COMENTARIO"
                      ></b-input>
                    </b-field>
                  </b-field>
                </div>
              </div>

              <div class="add-global-controls">
                <b-button type="is-success" @click="editFamily(0, props.row)"
                  >Actualizar familia</b-button
                >

                <b-button type="is-danger" @click="deleteFamily(0, props.row)"
                  >Borrar familia</b-button
                >
              </div>

              <h3 class="title-expandable-item">
                <strong>Subfamilias</strong>
              </h3>

              <b-field
                grouped
                class="special-margin title-expandable-item-special"
              >
                <b-field
                  label="Subfamilias"
                  expanded
                  label-position="inside"
                  required
                >
                  <b-select
                    placeholder="Ejemplo: Producto"
                    expanded
                    icon="shape-outline"
                    v-model="props.row.selectedSCat"
                    @input="changeSCategory"
                  >
                    <option value="">Seleccione una subfamilia</option>
                    <option
                      v-for="item in props.row.sCategories"
                      :key="item.CLAVE_SFAM"
                      :value="item"
                    >
                      {{ item.CLAVE_SFAM }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>

              <div v-if="props.row.selectedSCat">
                <b-field grouped class="special-margin">
                  <b-field
                    label="Clave de la subfamilia (Requerido)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: Acero"
                      expanded
                      icon="numeric"
                      v-model="props.row.selectedSCat.CLAVE_SFAM"
                      required
                      disabled
                    ></b-input>
                  </b-field>
                  <b-field
                    label="Descripción de la subfamilia (Opcional)"
                    expanded
                    label-position="inside"
                  >
                    <b-input
                      placeholder="Ejemplo: Descripción de aceros"
                      expanded
                      icon="clipboard"
                      v-model="props.row.selectedSCat.DESC_SFAM"
                    ></b-input>
                  </b-field>
                </b-field>
                <div
                  v-for="(item, index) in props.row.selectedSCat.COMMENT"
                  :key="index"
                  class="title-expandable-item-special"
                >
                  <b-field grouped class="special-margin">
                    <b-field
                      :label="'Comentario ' + (index + 1) + ' (Opcional)'"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: Familia usada en proveedor 1"
                        icon="office-building-outlinee"
                        v-model="item.COMENTARIO"
                      ></b-input>
                    </b-field>
                  </b-field>
                </div>
              </div>

              <div class="add-global-controls" v-if="props.row.selectedSCat">
                <b-button
                  type="is-success"
                  @click="editFamily(1, props.row.selectedSCat)"
                  >Actualizar subfamilia</b-button
                >

                <b-button
                  type="is-danger"
                  @click="deleteFamily(1, props.row.selectedSCat)"
                  >Borrar subfamilia</b-button
                >
              </div>

              <div v-if="checkCategorieLevel(props.row, 1)">
                <h3 class="title-expandable-item">
                  <strong>Subsubfamilias</strong>
                </h3>

                <b-field
                  grouped
                  class="special-margin title-expandable-item-special"
                >
                  <b-field
                    label="Subsubfamilias"
                    expanded
                    label-position="inside"
                  >
                    <b-select
                      placeholder="Ejemplo: Producto"
                      expanded
                      icon="shape-outline"
                      v-model="props.row.selectedSSCat"
                      @input="changeSSCategory"
                    >
                      <option value="" disabled v-if="!props.row.selectedSCat">
                        No hay una subfamilia seleccionada
                      </option>
                      <option value="" v-if="props.row.selectedSCat">
                        Seleccione una subsubfamilia
                      </option>
                      <option
                        v-for="item in props.row.sSCategories"
                        :key="item.CLAVE_SSFAM"
                        :value="item"
                      >
                        {{ item.CLAVE_SSFAM }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>

                <div v-if="props.row.selectedSSCat">
                  <b-field grouped class="special-margin">
                    <b-field
                      label="Clave de la subsubfamilia (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: Acero"
                        expanded
                        icon="numeric"
                        v-model="props.row.selectedSSCat.CLAVE_SSFAM"
                        required
                        disabled
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Descripción de la subsubfamilia (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: Descripción de aceros"
                        expanded
                        icon="clipboard"
                        v-model="props.row.selectedSSCat.DESC_SSFAM"
                      ></b-input>
                    </b-field>
                  </b-field>
                  <div
                    v-for="(item, index) in props.row.selectedSSCat.COMMENT"
                    :key="index"
                    class="title-expandable-item-special"
                  >
                    <b-field grouped class="special-margin">
                      <b-field
                        :label="'Comentario ' + (index + 1) + ' (Opcional)'"
                        expanded
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: Familia usada en proveedor 1"
                          icon="office-building-outlinee"
                          v-model="item.COMENTARIO"
                        ></b-input>
                      </b-field>
                    </b-field>
                  </div>
                </div>

                <div class="add-global-controls" v-if="props.row.selectedSSCat">
                  <b-button
                    type="is-success"
                    @click="editFamily(2, props.row.selectedSSCat)"
                    >Actualizar subsubfamilia</b-button
                  >

                  <b-button
                    type="is-danger"
                    @click="deleteFamily(2, props.row.selectedSSCat)"
                    >Borrar subsubfamilia</b-button
                  >
                </div>
              </div>

              <div v-if="checkCategorieLevel(props.row, 2)">
                <h3 class="title-expandable-item">
                  <strong>Subsubsubfamilias</strong>
                </h3>

                <b-field grouped class="special-margin">
                  <b-field
                    label="Subsubsubfamilias"
                    expanded
                    label-position="inside"
                  >
                    <b-select
                      placeholder="Ejemplo: Producto"
                      expanded
                      icon="shape-outline"
                      v-model="props.row.selectedSSSCat"
                    >
                      <option value="" disabled v-if="!props.row.selectedSSCat">
                        No hay una subsubfamilia seleccionada
                      </option>
                      <option value="" v-if="props.row.selectedSSCat">
                        Seleccione una subsubsubfamilia
                      </option>
                      <option
                        v-for="item in props.row.sSSCategories"
                        :key="item.CLAVE_SSSFAM"
                        :value="item"
                      >
                        {{ item.CLAVE_SSSFAM }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>

                <div v-if="props.row.selectedSSSCat">
                  <b-field grouped class="special-margin">
                    <b-field
                      label="Clave de la subsubsubfamilia (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: Acero"
                        expanded
                        icon="numeric"
                        v-model="props.row.selectedSSSCat.CLAVE_SSSFAM"
                        required
                        disabled
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Descripción de la subsubsubfamilia (Opcional)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: Descripción de aceros"
                        expanded
                        icon="clipboard"
                        v-model="props.row.selectedSSSCat.DESC_SSSFAM"
                        required
                      ></b-input>
                    </b-field>
                  </b-field>
                  <div
                    v-for="(item, index) in props.row.selectedSSSCat.COMMENT"
                    :key="index"
                    class="title-expandable-item-special"
                  >
                    <b-field grouped class="special-margin">
                      <b-field
                        :label="'Comentario ' + (index + 1) + ' (Opcional)'"
                        expanded
                        label-position="inside"
                      >
                        <b-input
                          placeholder="Ejemplo: Familia usada en proveedor 1"
                          icon="office-building-outlinee"
                          v-model="item.COMENTARIO"
                        ></b-input>
                      </b-field>
                    </b-field>
                  </div>
                </div>

                <div
                  class="add-global-controls"
                  v-if="props.row.selectedSSSCat"
                >
                  <b-button
                    type="is-success"
                    @click="editFamily(3, props.row.selectedSSSCat)"
                    >Actualizar subsubsubfamilia</b-button
                  >

                  <b-button
                    type="is-danger"
                    @click="deleteFamily(3, props.row.selectedSSSCat)"
                    >Borrar subsubsubfamilia</b-button
                  >
                </div>
              </div>

              <div class="add-global-controls">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="openAddSubCategory(props.row, 0)"
                    >Agregar subfamilias</b-button
                  >
                  <b-button
                    type="is-primary"
                    :disabled="!props.row.selectedSCat"
                    @click="openAddSubCategory(props.row, 1)"
                    >Agregar subsubfamilias</b-button
                  >
                  <b-button
                    type="is-primary"
                    :disabled="!props.row.selectedSSCat"
                    @click="openAddSubCategory(props.row, 2)"
                    >Agregar subsubsubfamilias</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddCategoryModal from "./AddCategoryModal";
import AddSubFamiliesModal from "./AddSubFamiliesModal";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { validateFields, checkValidationErrors } from "../../../utils/fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "CategoriesList",
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedCategories: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_FAM",
          label: "Clave de familia",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESC_FAM",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      categoryRequiredErrors: {
        CLAVE_FAM: false,
        DESC_FAM: false,
      },
      masks: Masks,
    };
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "categories");
  },
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETCATEGORIES"));
    allPromises.push(this.$store.dispatch("GETALLSCATEGORIES"));

    await Promise.all(allPromises);
  },
  methods: {
    async reloadInformation() {
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCATEGORIES"));
      allPromises.push(this.$store.dispatch("GETALLSCATEGORIES"));

      await Promise.all(allPromises);
    },
    openAddCategory() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddCategoryModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddSubCategory(category, subCategory) {
      const parents = {
        familyKey: category.CLAVE_FAM,
        selectedSCat: { ...category.selectedSCat },
        selectedSSCat: { ...category.selectedSSCat },
        selectedSSSCat: { ...category.selectedSSSCat },
      };
      let categoryName = "";
      /**
       * Creates object with necesary childrens
       */
      switch (subCategory) {
        // subCategory
        case 0:
          categoryName = "Subfamilia";
          break;
        // subsubCategory
        case 1:
          categoryName = "Subsubfamilia";
          break;
        // subsubsubCategory
        case 2:
          categoryName = "Subsubsubfamilia";
          break;
        // subCategory
        default:
          categoryName = "Subfamilia";
          break;
      }
      const modal = this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddSubFamiliesModal,
        props: {
          fathersInformation: parents,
          nameOfCategory: categoryName,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
      /*
       * Show new added subcategories when modal closes
       */
      modal.$on("close", () => {
        for (let i = 0; i < this.categories.length; i += 1) {
          if (this.categories[i].CLAVE_FAM === category.CLAVE_FAM) {
            if (categoryName === "Subsubfamilia") {
              this.categories[i].sSCategories = this.sSCategories.filter(
                (singleSSCategory) => {
                  return (
                    singleSSCategory.CLAVE_FAM === category.CLAVE_FAM &&
                    singleSSCategory.CLAVE_SFAM ===
                      category.selectedSCat.CLAVE_SFAM
                  );
                }
              );
            }

            if (categoryName === "Subsubsubfamilia") {
              this.categories[i].sSSCategories = this.sSSCategories.filter(
                (singleSSSCategory) => {
                  return (
                    singleSSSCategory.CLAVE_FAM === category.CLAVE_FAM &&
                    singleSSSCategory.CLAVE_SFAM ===
                      category.selectedSCat.CLAVE_SFAM &&
                    singleSSSCategory.CLAVE_SSFAM ===
                      category.selectedSSCat.CLAVE_SSFAM
                  );
                }
              );
            }
          }
        }
      });
    },
    changeSCategory(event) {
      for (let i = 0; i < this.categories.length; i += 1) {
        if (this.categories[i].CLAVE_FAM === event.CLAVE_FAM) {
          this.categories[i].selectedSSCat = "";
          this.categories[i].selectedSSSCat = "";
          this.categories[i].sSCategories = this.sSCategories.filter(
            (singleCategory) => {
              return (
                singleCategory.CLAVE_FAM === event.CLAVE_FAM &&
                singleCategory.CLAVE_SFAM === event.CLAVE_SFAM
              );
            }
          );
          this.categories[i].sSSCategories = [];
        }
      }
    },
    changeSSCategory(event) {
      for (let i = 0; i < this.categories.length; i += 1) {
        if (this.categories[i].CLAVE_FAM === event.CLAVE_FAM) {
          this.categories[i].selectedSSSCat = "";
          this.categories[i].sSSCategories = this.sSSCategories.filter(
            (singleCategory) => {
              return (
                singleCategory.CLAVE_FAM === event.CLAVE_FAM &&
                singleCategory.CLAVE_SFAM === event.CLAVE_SFAM &&
                singleCategory.CLAVE_SSFAM === event.CLAVE_SSFAM
              );
            }
          );
        }
      }
    },
    /**
     *
     * @desc updates a category or scategory depending on type
     */
    async editFamily(type, categoryInformation) {
      try {
        let error = false;
        if (type === 0) {
          if (!categoryInformation.CLAVE_FAM || !categoryInformation.DESC_FAM) {
            error = true;
          }
        } else if (type === 1) {
          if (
            !categoryInformation.CLAVE_SFAM ||
            !categoryInformation.DESC_SFAM
          ) {
            error = true;
          }
        } else if (type === 2) {
          if (
            !categoryInformation.CLAVE_SSFAM ||
            !categoryInformation.DESC_SSFAM
          ) {
            error = true;
          }
        } else if (type === 3) {
          if (
            !categoryInformation.CLAVE_SSSFAM ||
            !categoryInformation.DESC_SSSFAM
          ) {
            error = true;
          }
        }

        if (error) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la familia en la base de datos, revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        let response = await this.$store.dispatch(
          type === 0 ? "EDITMAINCATEGORY" : "EDITSCATEGORY",
          { categoryInformation, type }
        );
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se guardó exitosamente la familia",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al guardar la familia en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la familia en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc deletes a category or scategory depending on type
     */
    async deleteFamily(type, categoryInformation) {
      try {
        this.$buefy.dialog.confirm({
          title: "Borrar información",
          message: "Esta seguro que desea borrar esta información?",
          confirmText: "Borrar información",
          type: "is-danger",
          hasIcon: true,
          onCancel: () => {},
          onConfirm: async () => {
            let response = await this.$store.dispatch(
              type === 0 ? "EDITMAINCATEGORY" : "EDITSCATEGORY",
              {
                categoryInformation: { ...categoryInformation, STATUS: "DE" },
                type,
              }
            );
            if (response === "Success") {
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se guardó exitosamente la familia",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                onConfirm: () => {},
                cancelText: "cancelar",
                canCancel: false,
              });
              this.reloadInformation();
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al guardar la familia en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          },
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al guardar la familia en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    checkCategorieLevel(category, level) {
      if (level === 1) {
        return category.selectedSCat;
      } else if (level === 2) {
        return category.selectedSSCat;
      }
      return false;
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    sCategories() {
      return this.$store.getters.SUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    sSCategories() {
      return this.$store.getters.SUBSUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    sSSCategories() {
      return this.$store.getters.SUBSUBSUBCATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        return singleCategoryWithProp;
      });
    },
    categories() {
      /**
       * Map main categories with selected sub categories
       */
      return this.$store.getters.CATEGORIES.map((singleCategory) => {
        let singleCategoryWithProp = { ...singleCategory };
        // Add sub categories to father categories
        singleCategoryWithProp.sCategories = this.sCategories.filter(
          (singleSCategory) => {
            return singleSCategory.CLAVE_FAM === singleCategory.CLAVE_FAM;
          }
        );
        // If category is alreeady selected change the current subsubCategory
        singleCategoryWithProp.sSCategories = [];
        // If category is alreeady selected change the current subsubsubCategory
        singleCategoryWithProp.sSSCategories = [];
        singleCategoryWithProp.selectedSCat = "";
        singleCategoryWithProp.selectedSSCat = "";
        singleCategoryWithProp.selectedSSSCat = "";
        return singleCategoryWithProp;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
